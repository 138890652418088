// @flow
import { KebabList } from '@getatomi/neon';

import type { TaskActions } from 'src/domains/Tasks/ClassTasks/utils/getTaskActionsById';
import getSubjectColors from 'src/utils/getSubjectColors';

import type { ClassTask, ClassTaskContent } from './useGetClassTasks';
import { groupClassTasks } from '../utils/groupTasks';
import ClassTasksListCard from './ClassTasksListCard';

type ClassTasksListPops = {|
  getTaskActions: (id: string) => TaskActions,
  onStudentCountClick: (content: ClassTaskContent) => mixed,
  studentCount: number,
  taskProgressReportUrlFactory: (taskId: string) => string,
  tasksData: $ReadOnlyArray<ClassTask>,
|};

export default function ClassTasksList(props: ClassTasksListPops) {
  const { tasksData, getTaskActions, studentCount, onStudentCountClick, taskProgressReportUrlFactory } = props;

  const sections = groupClassTasks(tasksData);

  return (
    <KebabList>
      {Object.keys(sections).map((sectionName) => {
        return (
          <KebabList.Section key={sectionName} title={sectionName} testHook={`task-list-section:${sectionName}`}>
            {sections[sectionName].map((task) => {
              const subjectColorRange = getSubjectColors(task.subject.groupCode, task.subject.color);

              return (
                <ClassTasksListCard
                  key={task.id}
                  // All KebabList.Section children must have a `colors` prop,
                  // so we have to define this on the ClassTasksListCard
                  colors={subjectColorRange}
                  getTaskActions={getTaskActions}
                  onStudentCountClick={onStudentCountClick}
                  studentCount={studentCount}
                  task={task}
                  taskProgressReportUrlFactory={taskProgressReportUrlFactory}
                />
              );
            })}
          </KebabList.Section>
        );
      })}
    </KebabList>
  );
}

// @flow
import { useState } from 'react';
import { AlertDialog, Box, Container, Divider, Heading, HelpInfo, Stack, Text, Textarea } from '@getatomi/neon';
import { Controller } from 'react-hook-form';
import pluralize from 'pluralize';

import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import { trackingCtas } from 'src/constants/tracking';
import links from 'src/constants/links';

import useBulkAddStudentsToClassForm, { type OutboundFields } from './useBulkAddStudentsToClassForm';

export type BulkAddStudentsToClassFormProps = {|
  allUsersEmails: $ReadOnlyArray<string>,
  bulkAddUsers: Function,
  classId: string,
  className: string,
  isAddingUsers: boolean,
  onClose: () => mixed,
  onGoBack: (e: SyntheticMouseEvent<HTMLButtonElement>) => mixed,
  onSuccess: (countExistingUsers: number, countNewUsers: number) => mixed,
|};

export default function BulkAddStudentsToClassForm(props: BulkAddStudentsToClassFormProps) {
  const { allUsersEmails, bulkAddUsers, classId, className, isAddingUsers, onClose, onGoBack, onSuccess } = props;

  const [newUsers, setNewUsers] = useState<Array<string>>([]);
  const [showNewUsersAlert, setShowNewUsersAlert] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<Array<string>>([]);

  const { control, emails, fields, form, onConfirm } = useBulkAddStudentsToClassForm({
    context: { existingUsersEmails: allUsersEmails },
    defaultValues: {
      emails: '',
    },
    onNewUsersDetected: (newUserEmails) => {
      setNewUsers(newUserEmails);
      setShowNewUsersAlert(true);
    },
    onSubmitSuccess: async ({ emails: userEmails, countUsers, countNewUsers }: OutboundFields) => {
      const { errors } = await bulkAddUsers({ classId: String(classId), userEmails });

      if (errors) {
        const validationErrors = errors.map((gqlError) => gqlError.message);
        setServerErrors(validationErrors);
        return setShowNewUsersAlert(false);
      }

      onSuccess(countUsers, countNewUsers);
      onClose();
    },
    onSubmitFail: () => {
      onClose();
    },
  });

  const countNewUsers = newUsers.length;
  const countEmails = emails.length;
  const countErrors = serverErrors?.length ?? 0;
  const hasInviteErrors = countErrors > 0;

  return (
    <Box as="form" {...form} marginBottom="spacingLarge4X">
      <Container maxWidth="sizeContainerRoot" textAlign="center" marginBottom="spacingLarge4X">
        {hasInviteErrors ? (
          <Heading as="h1">
            {countErrors === countEmails ? 'That didn’t work!' : 'That was only partially successful'}
          </Heading>
        ) : (
          <Stack spacing="spacingLarge2X">
            <Heading as="h1">Bulk add students to {className}</Heading>
            <Text as="p" variant="lead">
              Import students to your class using a list of email addresses. New students will be sent invites to join
              your school account and added to the class, all in one go!
            </Text>
          </Stack>
        )}
      </Container>
      <Container maxWidth="sizeContainerSmall">
        <Stack spacing="spacingLarge4X">
          <Divider />
          {hasInviteErrors ? (
            <>
              <ValidationErrors errors={serverErrors} />
              <Box textAlign="center">
                <Button onClick={onClose}>Done</Button>
              </Box>
            </>
          ) : (
            <>
              <Controller
                control={control}
                name="emails"
                render={({ field }) => <Textarea {...field} {...fields.emails} rows={5} />}
              />
              <Box textAlign="center">
                <Stack spacing="spacingLarge3X">
                  <Button
                    type="submit"
                    isLoading={!showNewUsersAlert && isAddingUsers}
                    trackingData={{
                      classId,
                      cta: trackingCtas.classBulkAddUsers,
                      count: countEmails,
                      userType: 'students',
                    }}
                  >
                    {countEmails > 0 ? `Add ${countEmails} ${pluralize('student', countEmails)}` : 'Add students'}
                  </Button>
                  <Text variant="bodySmall1X" color="colorTextSubtler">
                    or{' '}
                    <Button variant="text" size="small" onClick={onGoBack}>
                      Go back.
                    </Button>
                  </Text>
                </Stack>
              </Box>
            </>
          )}
          <HelpInfo>
            Having trouble?{' '}
            <Link href={links.support.bulkAddStudentsToClass} variant="monochrome" isExternal>
              Learn more about bulk adding students in the Help Centre.
            </Link>
          </HelpInfo>
        </Stack>
      </Container>

      <AlertDialog
        isOpen={showNewUsersAlert}
        heading="New students will receive invites to the account"
        variant="warning"
        onConfirmLabel={`Invite ${countNewUsers} new ${pluralize('student', countNewUsers)}`}
        onConfirm={onConfirm}
        onDismissLabel="Cancel"
        onDismiss={() => setShowNewUsersAlert(false)}
        isLoading={isAddingUsers}
      >
        <Stack spacing="spacingSmall2X">
          <p>
            The following{' '}
            <strong>
              {countNewUsers} {pluralize('email', countNewUsers)}
            </strong>{' '}
            {pluralize('was', countNewUsers)} not found on your school account and will be sent an invitation to join.
            You can remove {countNewUsers > 1 ? 'them' : 'it'} from the list if you do not wish to invite{' '}
            {countNewUsers > 1 ? 'them' : 'it'}.
          </p>
          <Box
            maxHeight={150}
            overflowX="auto"
            // scrollable areas must be focusable for a11y
            tabIndex={0}
          >
            <ul>
              {newUsers.map((email) => (
                <Box as="li" key={email} listStyle="disc" marginLeft="spacingLarge1X">
                  {email}
                </Box>
              ))}
            </ul>
          </Box>
        </Stack>
      </AlertDialog>
    </Box>
  );
}

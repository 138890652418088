// @flow

import type { AccountFeature } from 'src/graphql/types/generated/GetModule';

export function getIsTeacherReviewFeatureEnabled({
  accountFeatures,
}: {
  accountFeatures: $ReadOnlyArray<AccountFeature>,
}): boolean {
  const isEnabledInEnv: boolean = config.RUNTIME_FEATURE_TEACHER_REVIEW === 'true';
  const isEnabledInAccount: boolean = accountFeatures.includes('TEACHER_REVIEW');
  return isEnabledInEnv || isEnabledInAccount;
}

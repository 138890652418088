// @flow
import _ from 'lodash';
import { Box, Flex, ResultSummary, TextLoader, Text, Avatar as NeonAvatar, Button } from '@getatomi/neon';

import { formatCappedDuration } from 'src/utils/duration';
import { formatPercentageAsString } from 'src/utils/formatPercentage';
import Avatar from 'src/components/Avatar/Avatar';
import Date from 'src/components/Date/Date';
import Link, { UnstyledLink } from 'src/components/Link/Link';
import StudentStrength from 'src/domains/ViewingQuizResults/Marks/StudentStrength/StudentStrength';
import type { MarksStudent } from 'src/domains/ViewingQuizResults/types';
import type { LessonMetrics } from 'src/domains/ViewingQuizResults/Marks/StudentStrength/StudentStrength';

import type { MarksQuestion } from '../prepareMarksData/prepareMarksData';
import { isPartOfCohort, cohorts } from '../studentCohorts';

type Cell<T> = {
  row: {
    original: MarksStudent,
  },

  // False positive - Even if Cell() gets called, the props that are only used inside the component
  // are not be considered "used" by Cell().
  // eg: `cell: ({ value }: Cell<MarksQuestion>)`
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md#known-issueslimitations
  // eslint-disable-next-line
  getValue: () => T,
};

type SimpleTextProps = {
  testHook: string,
};

function CellLoader() {
  return (
    <div style={{ width: `${_.random(40, 90)}%` }}>
      <TextLoader />
    </div>
  );
}

function AvatarLoader() {
  return (
    <Flex direction="row" alignItems="center" gap={6}>
      <NeonAvatar variant="muted" />
      <CellLoader />
      <CellLoader />
    </Flex>
  );
}

function Skipped({ testHook }: SimpleTextProps) {
  return (
    <Text color="colorTextSubtler" aria-hidden testHook={testHook}>
      -
    </Text>
  );
}

function Empty({ testHook }: SimpleTextProps) {
  return <Text aria-hidden testHook={testHook} />;
}

function isEmpty(cell): boolean {
  return cell.row.original?.hasPartialAttempt || cell.row.original?.hasAttempt === false;
}

export default function prepareMarksColumns(props: {
  averageMarkMetrics: LessonMetrics,
  isTeacherReviewFeatureEnabled?: boolean,
  sequence: ?$ReadOnlyArray<string>,
  shouldHideStrength?: boolean,
  shouldTruncateFirstName?: boolean,
}) {
  const {
    sequence,
    averageMarkMetrics,
    shouldTruncateFirstName = false,
    shouldHideStrength = false,
    isTeacherReviewFeatureEnabled = false,
  } = props;

  return [
    {
      accessorKey: 'fullName',
      cell: ({ row }: Cell<$PropertyType<MarksStudent, 'fullName'>>) => {
        const isActive = row.original.hasAttempt || row.original.hasPartialAttempt;
        return (
          <Avatar
            user={row.original}
            variant={isActive ? undefined : 'muted'}
            withText
            withTruncatedFirstName={shouldTruncateFirstName}
          />
        );
      },
      header: 'Students',
      meta: {
        isHeading: true,
        loader: <AvatarLoader />,
      },
    },
    {
      accessorKey: 'mark',
      header: 'Mark',
      cell: ({ row, getValue }: Cell<$PropertyType<MarksStudent, 'mark'>>) => {
        const mark = getValue();
        if (mark == null) {
          return <Empty testHook="mark-empty" />;
        }
        if (isTeacherReviewFeatureEnabled) {
          return (
            <Flex alignItems="center" justifyContent="space-between" gap="spacingRoot" testHook="mark">
              <Text fontSize="fontSizeSmall1X" color="colorText">
                {formatPercentageAsString(mark)}
              </Text>
              <Button as={UnstyledLink} href={row.original.reviewUrl} variant="secondary" size="small1X">
                View responses
              </Button>
            </Flex>
          );
        }
        return (
          <Text fontSize="fontSizeSmall1X" color="colorText" testHook="mark">
            {formatPercentageAsString(mark)}
          </Text>
        );
      },
      sortDescFirst: false,
      meta: {
        loader: <CellLoader />,
      },
    },
    shouldHideStrength
      ? null
      : {
          accessorKey: 'strength',
          header: 'Strength',
          cell: ({ getValue, row }: Cell<$PropertyType<MarksStudent, 'strength'>>) => {
            const strength = getValue();
            const { mark } = row.original;
            if (strength == null || mark == null) {
              return <Empty testHook="question-empty" />;
            }
            return (
              <StudentStrength
                strength={strength}
                mark={mark}
                metrics={averageMarkMetrics}
                isRevisionNeeded={isPartOfCohort(row.original, cohorts.NEEDS_REVISION)}
              />
            );
          },
          sortDescFirst: false,
          meta: {
            loader: <CellLoader />,
          },
        },
    {
      accessorKey: 'completions',
      header: 'Attempts',
      cell: ({ getValue }: Cell<$PropertyType<MarksStudent, 'completions'>>) => (
        <Text fontSize="fontSizeSmall1X" color="colorText" testHook="completions">
          <Box as="span" testHook="hide-in-percy">
            {getValue()}
          </Box>
        </Text>
      ),
      sortDescFirst: false,
      meta: {
        loader: <CellLoader />,
      },
    },
    {
      accessorKey: 'lastAttempt',
      header: 'Last attempt',
      cell: ({ getValue, row }: Cell<$PropertyType<MarksStudent, 'lastAttempt'>>) => {
        if (row.original?.hasAttempt) {
          return <Date testHook="hide-in-percy" format="D MMM, YYYY" value={getValue()} />;
        }
        if (row.original?.hasPartialAttempt) {
          return <Empty testHook="last-attempt-empty" />;
        }
        return (
          <Text fontSize="fontSizeSmall1X" color="colorTextSubtler">
            Not done yet
          </Text>
        );
      },
      meta: {
        loader: <CellLoader />,
      },
    },
    {
      accessorKey: 'duration',
      header: 'Duration',
      cell: ({ getValue }: Cell<$PropertyType<MarksStudent, 'duration'>>) => {
        const duration = getValue();
        if (duration == null) {
          return <Empty testHook="duration-empty" />;
        }
        return (
          <Text fontSize="fontSizeSmall1X" color="colorText" testHook="duration">
            <Box as="span" testHook="hide-in-percy">
              {formatCappedDuration(duration)}
            </Box>
          </Text>
        );
      },
      sortDescFirst: false,
      meta: {
        loader: <CellLoader />,
      },
    },
    ...(sequence ?? []).map((contentItemId, index) => ({
      accessorKey: `questions.${contentItemId}`,
      header: `Q${index + 1}`,
      cell: (cell: Cell<MarksQuestion>) => {
        const value = cell.getValue();
        if (isEmpty(cell)) {
          return <Empty testHook="question-empty" />;
        }
        if (value == null || value.type === null) {
          return <Skipped testHook="question-skipped" />;
        }

        const { url, result, type, status } = value;

        return (
          <Link href={url}>
            <ResultSummary result={result} type={type} status={status} />
          </Link>
        );
      },
      enableSorting: false,
      meta: {
        isCenterAligned: true,
        loader: null,
      },
    })),
  ].filter(Boolean);
}

// @flow
import type { ContextRouter } from 'react-router';
import { Box, Button, Container, EmptyState, Flex, HelpInfo, Illustration, useToast } from '@getatomi/neon';

import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import useProgressReportDialog from 'src/hooks/components/useProgressReportDialog';
import { getTaskUrl, getTaskProgressReportUrl } from 'src/utils/routes';
import { trackEvent } from 'src/utils/tracking';
import { trackingCtas, trackingCtaTypes, trackingEvents } from 'src/constants/tracking';
import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

import type { ClassTask, CurrentClass, TaskStatus } from './useGetClassTasks';
import useDeleteTaskAlertDialog from '../components/useDeleteTaskAlertDialog/useDeleteTaskAlertDialog';
import getTaskActionsById from './utils/getTaskActionsById';
import useDuplicateTaskDialog from '../components/useDuplicateTaskDialog/useDuplicateTaskDialog';
import ClassTasksList from './ClassTasksList';
import ClassTasksListEmptyState from './ClassTasksListEmptyState';

export type ClassTasksProps = {|
  alert: ?React.Node,
  clearFilters: () => void,
  currentClass: CurrentClass,
  isFreePlan: boolean,
  region: string,
  router: ContextRouter,
  status?: TaskStatus,
  subscriptionId: string,
  taskListFilters: React.Node,
  tasksData: $ReadOnlyArray<ClassTask>,
  totalTaskCount: number,
|};

export default function ClassTasks(props: ClassTasksProps) {
  const {
    alert,
    clearFilters,
    currentClass,
    isFreePlan,
    status,
    subscriptionId,
    tasksData,
    totalTaskCount,
    region,
    router,
    taskListFilters,
  } = props;
  const { id: classId } = currentClass;

  const toast = useToast();

  const copyTaskUrlToClipboard = async (taskId: string) => {
    const url = `${window.location.origin}${getTaskUrl(subscriptionId, classId, taskId)}`;
    try {
      await navigator.clipboard.writeText(url);
      toast.success('Link copied to the clipboard.');
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.copyLink,
        type: trackingCtaTypes.button,
        url,
        taskId,
        subject_code: currentClass.subject.code,
      });
    } catch (error) {
      toast.error('There was an error copying the link to the clipboard. Please try again.');
    }
  };

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
  });

  const [progressReportDialog, { openProgressReportDialog }] = useProgressReportDialog({
    classId,
    isFreePlan,
    region,
    subscriptionId,
  });

  const [deleteTaskAlertDialog, { open: openDeleteTaskAlertDialog }] = useDeleteTaskAlertDialog();

  const onDuplicateTaskSubmit = ({ duplicateTask, duplicateClassId }) => {
    openTaskDialog({ task: duplicateTask, classId: duplicateClassId });
  };

  const [duplicateTaskDialog, { open: openDuplicateTaskModal }] = useDuplicateTaskDialog({
    currentClass,
    onSubmit: onDuplicateTaskSubmit,
  });

  const taskProgressReportUrlFactory = (taskId: string) => getTaskProgressReportUrl(subscriptionId, classId, taskId);

  const getTaskActions = getTaskActionsById({
    tasks: tasksData,
    onTaskSelect: (id) => router.push(getTaskUrl(subscriptionId, classId, id)),
    onProgressSelect: (task) => router.push(getTaskProgressReportUrl(subscriptionId, classId, task.id)),
    onEditTaskSelect: (task) => openTaskDialog({ task }),
    onCopyLinkSelect: (id) => copyTaskUrlToClipboard(id),
    onDeleteTaskSelect: (task) => openDeleteTaskAlertDialog(task.id, task.status),
    onDuplicateTaskSelect: (task) => openDuplicateTaskModal(task),
  });

  // Scheduled tasks don't count in the totalTaskCount, so we need to check both
  if (tasksData.length === 0 && totalTaskCount === 0) {
    return (
      <Container>
        <EmptyState
          media={<Illustration name="emptystate-tasks" />}
          heading="You have not created any tasks yet."
          description="When you create a new task or start assigning lessons as tasks, they’ll show up here!"
          primaryAction={<Button onClick={openTaskDialog}>Create task</Button>}
        />
        <Box marginTop="spacingLarge4X">
          <HelpInfo>
            <Link href={links.support.task.creating} isExternal variant="monochrome">
              Need help creating tasks?
            </Link>
          </HelpInfo>
        </Box>
        {taskDialog}
      </Container>
    );
  }

  // Suppress the empty state if there is an alert present
  const emptyState = alert ? null : <ClassTasksListEmptyState onClearFilters={clearFilters} status={status} />;

  return (
    <>
      <Container maxWidth="sizeContainerRoot">
        {/* To match the current sizing of KebabList */}
        <Box marginInline="spacingLarge6X">
          <Flex justifyContent="space-between" alignItems="center" marginBottom="spacingLarge">
            {taskListFilters}
            <ButtonAdd onClick={() => openTaskDialog()} assistiveText="Create task">
              Create task
            </ButtonAdd>
          </Flex>
          {alert}
        </Box>
      </Container>

      {tasksData.length === 0 ? (
        emptyState
      ) : (
        <ClassTasksList
          tasksData={tasksData}
          getTaskActions={getTaskActions}
          studentCount={currentClass.studentCount}
          onStudentCountClick={(post) => openProgressReportDialog({ post, moduleId: post.moduleId })}
          taskProgressReportUrlFactory={taskProgressReportUrlFactory}
        />
      )}

      {progressReportDialog}
      {taskDialog}
      {deleteTaskAlertDialog}
      {duplicateTaskDialog}
    </>
  );
}

// @flow
import { type LocationShape, type ContextRouter } from 'react-router';
import { useState, useEffect } from 'react';
import invariant from 'invariant';
import Helmet from 'react-helmet';

import useSubscriptions from 'src/hooks/useSubscriptions';
import broadcasting from 'src/constants/broadcasting';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import useClassTaskListFilters, { type TaskFilterStatus } from './useClassTaskListFilters';
import useTaskListAlerts from './useTaskListAlerts';
import getClassTasksVariables from './utils/getClassTasksVariables';
import ClassTasks from './ClassTasks';
import TaskListLoader from '../components/TaskListLoader';
import useGetClassTasks from './useGetClassTasks';

export type Props = {|
  location: LocationShape,
  params: {
    classId: string,
    subscriptionId: string,
  },
  router: ContextRouter,
|};

export function ClassTasksContainer(props: Props) {
  const {
    params: { classId, subscriptionId },
    location: {
      pathname,
      query: { postId },
    },
    router,
  } = props;
  const defaultFilter = postId ? 'all' : 'current';
  const [, setNavigationContext] = useNavigationContext();
  const [statusFilter, setStatusFilter] = useState<TaskFilterStatus>(defaultFilter);

  const [taskListFilters, clearFilters] = useClassTaskListFilters({
    filters: { statusFilter, postId },
    onStatusFilterChange: setStatusFilter,
  });

  const queryVariables = getClassTasksVariables({
    classId,
    postId,
    statusFilter,
    subscriptionId,
  });

  const { loading, data, error, refetch } = useGetClassTasks({
    subscriptionId,
    variables: queryVariables,
  });

  const alert = useTaskListAlerts({
    filteredTaskCount: data?.tasks.length ?? 0,
    filters: { statusFilter, postId },
    pathname,
  });

  useEffect(() => {
    refetch();
  }, [statusFilter, refetch]);

  useSubscriptions([
    { channel: `class.${classId}`, event: broadcasting.events.CLASS_TASKS.CREATED },
    { channel: `class.${classId}`, event: broadcasting.events.CLASS_TASKS.UPDATED },
    { channel: `class.${classId}`, event: broadcasting.events.CLASS_TASKS.DELETED },
    { channel: `class.${classId}`, event: broadcasting.events.CLASS_USER_PROGRESS.UPDATED },
  ]);

  const className = data?.class.name ?? 'Class';

  useEffect(() => {
    setNavigationContext({
      location: 'classTasks',
      metadata: {
        accountId: subscriptionId,
        classId,
        title: `${className} Tasks`,
      },
    });
  }, [subscriptionId, classId, className, setNavigationContext]);

  const title = <Helmet title="Tasks" />;

  if (loading) {
    return (
      <>
        {title}
        <TaskListLoader numFilters={1} withAddTaskCta />
      </>
    );
  }

  if (error) {
    return (
      <>
        {title}
        <GraphQLError error={error} description="We couldn’t load the tasks." />
      </>
    );
  }

  // QueryRenderProps makes all fields optional but at this point we should have data
  invariant(data, 'Tasks data should not be null');

  return (
    <>
      {title}
      <ClassTasks
        alert={alert}
        currentClass={data.class}
        isFreePlan={data.isFreePlan}
        region={data.region}
        router={router}
        taskListFilters={taskListFilters}
        clearFilters={clearFilters}
        subscriptionId={subscriptionId}
        tasksData={data.tasks}
        totalTaskCount={data.totalTaskCount}
      />
    </>
  );
}

export default ClassTasksContainer;

// @flow

import postTypes from 'src/constants/postTypes';
import PostListItem from 'src/components/PostList/PostListItem/PostListItem';
import PostListItemLink from 'src/components/PostList/PostListItemLink/PostListItemLink';
import usePostActions from 'src/hooks/components/usePostActions';

import type { UserData, LessonData } from '../useGetModule/useGetModule';
// import TaskDueDate from './TaskDueDate';

type SubjectType = {|
  code: string,
  color: string,
  groupCode: string,
|};

type Params = {|
  classId: string,
  moduleId: string,
  subscriptionId: string,
|};

type Props = {
  isSelected?: boolean,
  isTeacherReviewFeatureEnabled: boolean,
  lesson: LessonData,
  onCloseProgressDialog?: () => mixed,
  onSubmitTask: () => mixed,
  onToggleSelection?: () => mixed,
  onViewResponses: (url: string) => mixed,
  onViewTasks: (url: string) => mixed,
  params: Params,
  region: string,
  studentCount: number,
  subject: SubjectType,
  userData: UserData,
};

function ModulePostListItem(props: Props) {
  const {
    isSelected,
    isTeacherReviewFeatureEnabled,
    onCloseProgressDialog,
    onSubmitTask,
    onToggleSelection,
    onViewResponses,
    onViewTasks,
    params,
    params: { classId, subscriptionId, moduleId },
    region,
    lesson,
    studentCount,
    subject,
    userData,
  } = props;

  const { isChild, isFreePlan, isSchoolPlan, isStudent } = userData;

  const [
    postActionsElements,
    { assignTask, viewProgress, viewResponses, copyLink, copyEmbed, shareToGoogleClassroom },
  ] = usePostActions({
    isFreePlan,
    subscriptionId,
    classId,
    subject: {
      code: subject.code,
      groupCode: subject.groupCode,
    },
    moduleId,
    lesson: {
      ...lesson,
      subjectCode: subject.code,
      // TODO - add tasks once we reinstate "View linked tasks" action and task due dates
      tasks: [],
    },
    onCloseProgressDialog,
    onSubmitTask,
    onViewResponses,
    onViewTasks,
    region,
  });

  const { isLocked } = lesson;
  const actions =
    isLocked || isStudent
      ? []
      : [
          { key: 'assign', label: 'Assign as task', onSelect: assignTask },
          // tasks.length > 0 && { label: 'View linked tasks', onSelect: viewTasks },
          { key: 'view-progress', label: 'View progress report', onSelect: viewProgress },
          isTeacherReviewFeatureEnabled && lesson.type === postTypes.challenge
            ? { key: 'view-responses', label: 'View student responses', onSelect: viewResponses }
            : null,
          { key: 'copy-link', label: 'Copy link', onSelect: copyLink },
          { key: 'copy-embed-code', label: 'Copy embed code', onSelect: copyEmbed },
          { key: 'share', label: 'Share to Google Classroom', onSelect: shareToGoogleClassroom },
        ].filter(Boolean);
  const canOpenProgressModal = !isStudent && !isLocked && studentCount > 0;

  return (
    <>
      <PostListItem
        postId={lesson.id}
        isSelected={isSelected}
        onToggleSelection={isLocked ? undefined : onToggleSelection}
        postName={lesson.name}
        testHook={`post-list-item-${lesson.type}${isLocked ? '-locked' : ''}`}
      >
        <PostListItemLink
          color={subject.color}
          isLocked={isLocked}
          progress={lesson.studentLessonMetrics}
          progressCount={lesson.completionCount ?? undefined}
          duration={lesson.duration}
          name={lesson.name}
          routeParams={{ ...params, postId: lesson.id }}
          studentsCount={studentCount}
          testHook={`post-list-item${isLocked ? '-locked' : ''}`}
          type={lesson.type}
          upgradePromptProps={{ isSchoolPlan, isLoggedInAsChild: isChild, subscriptionId: +subscriptionId }}
          withStrengthIndicator={lesson.type === postTypes.challenge && isStudent}
          onProgressClick={canOpenProgressModal ? viewProgress : undefined}
          actions={actions.length > 0 ? actions : undefined}
          challengeTier={lesson.challengeTier}
          // dueDate={
          //   tasks.length > 0 ? (
          //     <TaskDueDate isLoggedInAsStudent={isStudent} isPostCompleted={isCompleted} tasks={tasks} />
          //   ) : undefined
          // }
        />
      </PostListItem>
      {postActionsElements}
    </>
  );
}

export default ModulePostListItem;

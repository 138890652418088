// @flow
import { useMutation } from '@apollo/client';

import type { UserGroupType } from 'src/types';
import type { AddClassUsers, AddClassUsersVariables } from 'src/graphql/types/generated/AddClassUsers';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import GET_CLASS_USERS from 'src/domains/Class/GetClassUsers.graphql';

import ADD_USERS_TO_CLASS from './AddClassUsers.graphql';

type AddClassUsersParams = {|
  classId: string,
  userEmails: $ReadOnlyArray<string>,
|};

export type Output = {|
  addClassUsers: (params: AddClassUsersParams) => Promise<void>,
  isAddingClassUsers: boolean,
|};

export default function useAddClassUsers(userType: UserGroupType): Output {
  const [addClassUsers, { loading }] = useMutation<AddClassUsers, AddClassUsersVariables>(ADD_USERS_TO_CLASS, {
    refetchQueries: [GET_CLASS_USERS],
    awaitRefetchQueries: true,
    // Return both data and errors to handle partial success when bulk adding users. Without this,
    // the refetchQueries doesn't execute for partial success.
    // https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
    errorPolicy: 'all',
  });

  return {
    addClassUsers: async (params: AddClassUsersParams) => {
      trackEvent(trackingEvents.classAddUsersRequested, {
        classId: params.classId,
        emails: params.userEmails,
        userType,
      });
      return addClassUsers({
        variables: {
          input: {
            classId: params.classId,
            users: params.userEmails.map((email) => ({
              email,
              role: userType === 'students' ? 'STUDENT' : 'TEACHER',
            })),
          },
        },
      });
    },
    isAddingClassUsers: loading,
  };
}

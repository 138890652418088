// @flow

import customerTypes from 'src/constants/authCustomerTypes';
import userRoles from 'src/constants/userRoles';

import { type NavigationContext, serializeNavigationContext } from './navigationContext';

// Use these types when you need to support both string and number ids.
// Otherwise just use string or number as needed in your types directly.
type SubscriptionId = string | number;
type ClassId = string | number;
type ModuleId = string | number;
type PostId = string | number;
type LessonId = string | number;

export type GetLoginUrlWithRedirectLocation = {
  pathname: string,
  search?: string,
};

export type GetLtiUrlOptions = {
  source?: string | null,
};

export const isLessonLocationsUrl = (url: string): boolean => /^\/posts\/(\d+)/.test(url);

export const isLtiUrl = (url: string): boolean => /\/lti\//.test(url);

export const getLtiUrl = (subscriptionId: SubscriptionId, { source }: GetLtiUrlOptions = {}): string => {
  const url = `/subscriptions/${subscriptionId}/lti/classes`;
  const params = new URLSearchParams();

  if (source) {
    params.append('source', source);
  }

  return Array.from(params).length > 0 ? `${url}?${params.toString()}` : url;
};

export const getLtiClassUrl = (subscriptionId: SubscriptionId, classId: ClassId): string => {
  return `${getLtiUrl(subscriptionId)}/${classId}`;
};

export const getLtiClassModuleUrl = (subscriptionId: SubscriptionId, classId: ClassId, moduleId: ModuleId): string => {
  return `${getLtiClassUrl(subscriptionId, classId)}/modules/${moduleId}`;
};

export const getLtiClassModuleLessonUrl = (
  subscriptionId: SubscriptionId,
  classId: ClassId,
  moduleId: ModuleId,
  lessonId: LessonId
): string => {
  return `${getLtiClassUrl(subscriptionId, classId)}/modules/${moduleId}/posts/${lessonId}`;
};

export const getLoginUrlWithRedirect = (location: GetLoginUrlWithRedirectLocation): string => {
  const { pathname, search } = location;
  return `/login?redirect=${pathname}${search ? encodeURIComponent(search) : ''}`;
};

export const getSubjectsSelectionUrl = (subscriptionId: SubscriptionId): string => {
  return `/subscriptions/${subscriptionId}/subjects-selection`;
};

export const getRegistrationWelcomeUrl = (subscriptionId: string): string => {
  return `/subscriptions/${subscriptionId}/welcome`;
};

export const getSubscriptionUrl = (subscriptionId: SubscriptionId): string =>
  `/subscriptions/${subscriptionId}/classes`;

export const getInsightsUrl = (subscriptionId: SubscriptionId, insightType?: string): string => {
  if (insightType) {
    return `/subscriptions/${subscriptionId}/insights/${insightType}`;
  }
  return `/subscriptions/${subscriptionId}/insights`;
};

export type GetDashboardUrlOptions = {
  customerType?: string,
  isLti?: boolean,
  keywords?: string,
  source?: string | null,
  userRole?: string,
};

export const getDashboardUrl = (
  subscriptionId: SubscriptionId | null | void,
  { source, keywords, userRole, customerType }: GetDashboardUrlOptions = {}
): string => {
  if (!subscriptionId) return '/';

  if (userRole === userRoles.familyAccountOwner || customerType === customerTypes.family) {
    return getInsightsUrl(subscriptionId);
  }

  const url = getSubscriptionUrl(subscriptionId);

  if (customerType === customerTypes.student) {
    return url;
  }

  const params = new URLSearchParams();

  if (source) {
    params.append('source', source);
  }
  if (keywords) {
    params.append('keywords', keywords);
  }

  return Array.from(params).length > 0 ? `${url}?${params.toString()}` : url;
};

export const getClassUrl = (subscriptionId: SubscriptionId, classId: ClassId): string => {
  return `${getSubscriptionUrl(subscriptionId)}/${classId}`;
};

export const getClassModuleUrl = (subscriptionId: SubscriptionId, classId: ClassId, moduleId: ModuleId): string => {
  return `${getClassUrl(subscriptionId, classId)}/modules/${moduleId}`;
};

export const getClassTasksUrl = (subscriptionId: SubscriptionId, classId: ClassId): string => {
  return `${getSubscriptionUrl(subscriptionId)}/${classId}/tasks`;
};

export const getClassInsightsUrl = (subscriptionId: string, classId: string): string => {
  return `${getClassUrl(subscriptionId, classId)}/insights`;
};

export const getClassTopicInsightsUrl = (subscriptionId: string, classId: string, topicId: string): string => {
  return `${getClassInsightsUrl(subscriptionId, classId)}/topics/${topicId}`;
};

// Note that our current usage of getClassTaskUrl() always provides a postId
// so making the options type match that here.
//
// TODO: Consolidate `getClassTaskUrl()` and `getClassTasksUrl()`.
// Consider removing `getClassTaskUrl()` and add the `postId` option to `getClassTasksUrl()`.
export type GetClassTaskUrlOptions = {| postId: PostId |};

export const getClassTaskUrl = (
  subscriptionId: SubscriptionId,
  classId: ClassId,
  options: GetClassTaskUrlOptions
): string => {
  const classTasksUrl = getClassTasksUrl(subscriptionId, classId);
  return `${classTasksUrl}?postId=${options.postId}`;
};

export const getTaskUrl = (subscriptionId: string, classId: string, taskId: string): string =>
  `${getSubscriptionUrl(subscriptionId)}/${classId}/tasks/${taskId}`;

export const getTaskProgressReportUrl = (
  subscriptionId: string,
  classId: string,
  taskId: string,
  status?: string
): string => `${getTaskUrl(subscriptionId, classId, taskId)}/progress${status ? `?status=${status}` : ''}`;

export const getPostUrl = (
  subscriptionId: SubscriptionId,
  classId: ClassId,
  moduleId: ModuleId,
  postId: PostId,
  isEmbed: boolean = false
): string => {
  const postUrl = `${getClassModuleUrl(subscriptionId, classId, moduleId)}/posts/${postId}`;
  return isEmbed ? `${postUrl}?isEmbed=true` : postUrl;
};

export const getChallengeProgressReportUrl = (
  subscriptionId: string,
  classId: string,
  moduleId: string,
  postId: string
): string => {
  return `${getPostUrl(subscriptionId, classId, moduleId, postId)}/overview`;
};

export const getChallengeMarksUrl = (
  subscriptionId: string,
  classId: string,
  moduleId: string,
  postId: string
): string => {
  return `${getPostUrl(subscriptionId, classId, moduleId, postId)}/marks`;
};

export const getRevisionUrl = (subscriptionId: string, classId: string, revisionId: string): string =>
  `/subscriptions/${subscriptionId}/classes/${classId}/revisions/${revisionId}`;

export const getRevisionProgressReportUrl = (subscriptionId: string, classId: string, revisionId: string): string =>
  `${getRevisionUrl(subscriptionId, classId, revisionId)}/overview`;

export const getRevisionMarksUrl = (subscriptionId: string, classId: string, revisionId: string): string =>
  `${getRevisionUrl(subscriptionId, classId, revisionId)}/marks`;

export const getPostUniversalUrl = (postId: PostId, isEmbed?: boolean): string =>
  `${window.location.origin}/posts/${postId}${isEmbed ? '?isEmbed=true' : ''}`;

export const getSettingsUrl = (subscriptionId: SubscriptionId): string => {
  return `/subscriptions/${subscriptionId}/settings`;
};

export const getBillingUrl = (subscriptionId: SubscriptionId, anchor?: string): string =>
  `${getSettingsUrl(subscriptionId)}/billing${anchor ? `#${anchor}` : ''}`;

export const getSchoolAccountUrl = (subscriptionId: SubscriptionId): string =>
  `${getSettingsUrl(subscriptionId)}/school`;

export type GetSubscriptionUsersUrlArgs = {
  keywords?: string,
  page?: number,
  status?: string,
  subscriptionId: SubscriptionId,
};

export const getSubscriptionUsersUrl = ({ subscriptionId, page, status, keywords }: GetSubscriptionUsersUrlArgs) => {
  const url = `/subscriptions/${subscriptionId}/settings/users`;
  const params = new URLSearchParams();

  if (page) {
    params.append('page', String(page));
  }
  if (keywords) {
    params.append('keywords', keywords);
  }
  if (status) {
    params.append('status', status);
  }

  return Array.from(params).length > 0 ? `${url}?${params.toString()}` : url;
};

export const getUpgradeUrl = (subscriptionId: SubscriptionId, paymentFrequency?: string): string => {
  let url = `/subscriptions/${subscriptionId}/upgrade`;

  if (paymentFrequency) {
    url = `/subscriptions/${subscriptionId}/switch/${paymentFrequency}`;
  }

  return url;
};

export const getUpgradeSuccessUrl = (subscriptionId: string, paymentFrequency?: string): string =>
  `${getUpgradeUrl(subscriptionId, paymentFrequency)}/success`;

export const getExpiredUrl = (subscriptionId: SubscriptionId): string => `/subscriptions/${subscriptionId}/expired`;

export const getTasksUrl = (subscriptionId: SubscriptionId): string => `/subscriptions/${subscriptionId}/tasks`;

export const getRevisionPageUrl = (subscriptionId: SubscriptionId): string =>
  `/subscriptions/${subscriptionId}/revision`;

export const getLoginUrl = (): string => '/login';

export const getSocialLoginCallbackUrl = (provider: string): string =>
  `${window.location.origin}${getLoginUrl()}/${provider}`;

export const getSubscriptionsListUrl = (): string => '/subscriptions';

export const getInviteUrl = (inviteCode: string): string => `/register/invite/${inviteCode}`;

export const getErrorUrl = (code: string, inviteCode?: string): string =>
  `/error/${code}${inviteCode ? `?inviteCode=${inviteCode}` : ''}`;

export const getSubscriptionIdFromUrl = (url: string): number | null => {
  const matches = String(url).match(/^\/subscriptions\/(\d+)/);
  return matches && parseInt(matches[1], 10);
};

export const getMarkbookCompletionUrl = (subscriptionId: string, classId: string): string => {
  return `${getSubscriptionUrl(subscriptionId)}/${classId}/markbook-completion`;
};

export const getMarkbookMarksUrl = (subscriptionId: string, classId: string): string => {
  return `${getSubscriptionUrl(subscriptionId)}/${classId}/markbook-marks`;
};

export const getMarkbookTasksUrl = (subscriptionId: string, classId: string): string => {
  return `${getSubscriptionUrl(subscriptionId)}/${classId}/markbook-tasks`;
};

export const getTeacherReviewChallengeUrl = ({
  accountId,
  classId,
  itemId,
  moduleId,
  postId,
  returnTo,
  userId,
}: {|
  accountId: string,
  classId: string,
  itemId: string | null,
  moduleId: string,
  postId: string,
  returnTo: string,
  userId: string | null,
|}) => {
  const postUrl = `/accounts/${accountId}/classes/${classId}/modules/${moduleId}/posts/${postId}/review`;
  const params = new URLSearchParams({ returnTo });
  if (itemId) {
    params.append('itemId', itemId);
  }
  if (userId) {
    params.append('userId', userId);
  }
  const postUrlWithParams = `${postUrl}?${params.toString()}`;
  return postUrlWithParams;
};

export const getChallengeQuestionUrl = ({
  accountId,
  classId,
  isTeacherReviewFeatureEnabled,
  itemId,
  moduleId,
  postId,
  returnTo,
  userId,
}: {|
  accountId: string,
  classId: string,
  isTeacherReviewFeatureEnabled: boolean,
  itemId: string | null,
  moduleId: string,
  postId: string,
  returnTo: string,
  userId: string | null,
|}) => {
  if (isTeacherReviewFeatureEnabled) {
    return getTeacherReviewChallengeUrl({
      accountId,
      classId,
      itemId,
      moduleId,
      postId,
      returnTo,
      userId,
    });
  }

  const postUrl = `/accounts/${accountId}/classes/${classId}/preview/modules/${moduleId}/posts/${postId}`;
  const params = new URLSearchParams({ itemId: itemId ?? '', returnTo });
  const postUrlWithParams = `${postUrl}?${params.toString()}`;
  return postUrlWithParams;
};

export type GetChallengeFirstItemUrlArgs = {|
  accountId: string,
  classId: string,
  isEmbedded: boolean,
  isLti: boolean,
  moduleId: string,
  postId: string,
|};

// URL to the first item in a challenge in the Learn App.
// This is not the challenge start page.
export const getChallengeFirstItemUrl = ({
  accountId,
  classId,
  moduleId,
  postId,
  isEmbedded,
  isLti,
}: GetChallengeFirstItemUrlArgs) => {
  const accountUrlBase = `/accounts/${accountId}`;
  const accountUrl = isLti ? `${accountUrlBase}/lti` : accountUrlBase;
  const postUrl = `${accountUrl}/classes/${classId}/modules/${moduleId}/posts/${postId}`;
  return isEmbedded ? `${postUrl}?isEmbed=true` : postUrl;
};

export const getRevisionQuestionUrl = ({
  accountId,
  challengeId,
  classId,
  isTeacherReviewFeatureEnabled,
  itemId,
  returnTo,
  userId,
}: {|
  accountId: string,
  challengeId: string,
  classId: string,
  isTeacherReviewFeatureEnabled: boolean,
  itemId: string | null,
  returnTo: string,
  userId: string | null,
|}) => {
  if (isTeacherReviewFeatureEnabled) {
    const revisionUrl = `/accounts/${accountId}/classes/${classId}/revisions/${challengeId}/review`;
    const params = new URLSearchParams({ returnTo });
    if (itemId) {
      params.append('itemId', itemId);
    }
    if (userId) {
      params.append('userId', userId);
    }
    const revisionUrlWithParams = `${revisionUrl}?${params.toString()}`;
    return revisionUrlWithParams;
  }
  const revisionUrl = `/accounts/${accountId}/classes/${classId}/preview/revisions/${challengeId}`;
  const params = new URLSearchParams({ itemId: itemId ?? '', returnTo });
  const revisionUrlWithParams = `${revisionUrl}?${params.toString()}`;
  return revisionUrlWithParams;
};

export type GetRevisionFirstItemUrlArgs = {|
  accountId: string,
  challengeId: string,
  classId: string,

  // We pass the navigation context to the Learn App.
  // It will be passed back to us when the user returns to Carbon.
  // We then use that to determine which URL to redirect to when the Revision is finished.
  navigationContext: NavigationContext | null,
|};

// URL to the first item in a revision in the Learn App.
// This is not the revision start page.
export const getRevisionFirstItemUrl = ({
  accountId,
  classId,
  challengeId,
  navigationContext,
}: GetRevisionFirstItemUrlArgs) => {
  const revisionUrl = `/accounts/${accountId}/classes/${classId}/revisions/${challengeId}`;

  if (navigationContext) {
    const navigationContextParam = serializeNavigationContext(navigationContext);
    const params = new URLSearchParams({ navigationContext: navigationContextParam });
    const revisionUrlWithParams = `${revisionUrl}?${params.toString()}`;
    return revisionUrlWithParams;
  }

  return revisionUrl;
};

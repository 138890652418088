// @flow
import invariant from 'invariant';
import { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import type { ContextRouter } from 'react-router';
import _ from 'lodash';
import { Box, Container, Flex, Heading, Stack } from '@getatomi/neon';

import ActionBar from 'src/components/ActionBar/ActionBar';
import BackLink from 'src/components/BackLink/BackLink';
import Button from 'src/components/Button/Button';
import ButtonAdd from 'src/components/ButtonAdd/ButtonAdd';
import PostList from 'src/components/PostList/PostList';
import PostListItem from 'src/components/PostList/PostListItem/PostListItem';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import useTeacherRevisionDialog from 'src/components/useTeacherRevisionDialog/useTeacherRevisionDialog';
import Duration from 'src/components/Duration/Duration';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';
import { subscribeToClassProgress, subscribeToOwnProgress } from 'src/utils/pusher';
import { getClassUrl } from 'src/utils/routes';
import useSelectedItems from 'src/hooks/useSelectedItems';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import useGetModule, { type TopicData, type LessonData, type TransformedData } from './useGetModule/useGetModule';
import type { NewAttachedContent } from '../Tasks/types';
import SectionListItem from './SectionList/SectionListItem/SectionListItem';
import SectionList from './SectionList/SectionList';
import ModulePostListItem from './ModulePostListItem/ModulePostListItem';
import ModuleLoader from './ModuleLoader';
import { getIsTeacherReviewFeatureEnabled } from './utilities/teacherReviewFeature';

type Params = {|
  classId: string,
  moduleId: string,
  subscriptionId: string,
|};

type ModuleContainerProps = {|
  params: Params,
  router: ContextRouter,
|};

type ModuleProps = {|
  params: Params,
  queryData: TransformedData,
  router: ContextRouter,
|};

function Module(props: ModuleProps) {
  const { queryData, params, router } = props;

  const { subscriptionId, classId, moduleId } = params;

  const {
    class: classData,
    subject: subjectData,
    user: userData,
    module: moduleData,
    lessons,
    refetch,
    region,
  } = queryData;

  const isTeacherReviewFeatureEnabled = getIsTeacherReviewFeatureEnabled({
    accountFeatures: userData.enabledFeatures,
  });

  const Selecting = useSelectedItems(lessons.map((lesson) => lesson.id));
  const selectedLessons = lessons.filter((lesson) => Selecting.isSelected(lesson.id));

  const [progressDialogClosed, setProgressDialogClosed] = useState(null);

  const onSubmitTask = async () => {
    Selecting.clear();
  };

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
    onSubmit: onSubmitTask,
  });

  const [, setNavigationContext] = useNavigationContext();

  useEffect(() => {
    // set post context used by the back link
    setNavigationContext({
      location: 'module',
      metadata: {
        accountId: subscriptionId,
        classId,
        moduleId,
        title: moduleData.name,
      },
    });
  }, [classId, moduleId, moduleData.name, setNavigationContext, subscriptionId, userData]);

  useEffect(() => {
    return userData.isStudent
      ? subscribeToOwnProgress(+userData.id, () => refetch())
      : subscribeToClassProgress(+classId, () => refetch());
  }, [progressDialogClosed]); // eslint-disable-line react-hooks/exhaustive-deps

  const { dialog: teacherRevisionDialog, open: openTeacherRevisionDialog } = useTeacherRevisionDialog({
    isFreePlan: userData.isFreePlan,
    onCreate: (newRevision: NewAttachedContent) =>
      openTaskDialog({
        contents: [newRevision],
      }),
    region: queryData.region,
  });

  const onCloseProgressDialog = () => {
    // Update the progressDialogClosed state object as something new to trigger a re-subscription to the class progress
    setProgressDialogClosed({});
  };

  const onViewTasks = (url) => {
    router.push(url);
  };

  const onViewResponses = (url: string) => {
    // Here we need to redirect from Carbon to Learn.
    // Router redirects don't work, so we use a regular redirect instead.
    window.location.assign(url);
  };

  const renderLesson = (lesson: LessonData) => {
    return (
      <ModulePostListItem
        key={lesson.id}
        subject={{
          code: subjectData.code,
          color: subjectData.color,
          groupCode: subjectData.groupCode,
        }}
        studentCount={classData.studentCount}
        lesson={lesson}
        params={params}
        onCloseProgressDialog={onCloseProgressDialog}
        onSubmitTask={onSubmitTask}
        onToggleSelection={!userData.isStudent ? () => Selecting.select(lesson.id) : undefined}
        onViewResponses={onViewResponses}
        onViewTasks={onViewTasks}
        region={region}
        isSelected={Selecting.isSelected(lesson.id)}
        isTeacherReviewFeatureEnabled={isTeacherReviewFeatureEnabled}
        userData={userData}
      />
    );
  };

  const renderTopic = (topic: TopicData) => {
    if (topic.lessons.length) {
      return <PostList>{topic.lessons.map(renderLesson)}</PostList>;
    }
    if (topic.subtopics.length) {
      return (
        <PostList>
          {topic.subtopics.map((subtopic) => (
            <PostListItem
              key={subtopic.id}
              id={`section${subtopic.id}`}
              labels={subtopic.labels}
              subSectionName={subtopic.name}
            >
              <PostList>{subtopic.lessons.map(renderLesson)}</PostList>
            </PostListItem>
          ))}
        </PostList>
      );
    }
    return null;
  };

  const backLink = (
    <BackLink to={getClassUrl(subscriptionId, classId)} testHook="module-back-to-class-link">
      Back to {classData.name}
    </BackLink>
  );

  return (
    <>
      <Helmet>
        <title>{`${moduleData.name} | ${classData.name} | ${subjectData.name}`}</title>
      </Helmet>

      <Box display={{ base: 'none', tablet: 'initial' }}>
        <Container>
          <Box marginBottom="spacingLarge6X">{backLink}</Box>
        </Container>
      </Box>
      <Container maxWidth="sizeContainerRoot">
        <Flex alignItems={{ base: 'flex-start', tablet: 'baseline' }} justifyContent="space-between" gap="spacingLarge">
          <Heading as="h1" marginBottom="spacingLarge6X">
            {moduleData.name}
          </Heading>
          {!userData.isStudent && (
            <Box flexShrink={0}>
              <ButtonAdd
                assistiveText="Create revision"
                onClick={() =>
                  openTeacherRevisionDialog({
                    selectedTopicIds: moduleData.topics.map((topic) => topic.id),
                  })
                }
              >
                Create revision
              </ButtonAdd>
            </Box>
          )}
        </Flex>
      </Container>

      <Container maxWidth="sizeContainerRoot" paddingInline={{ base: 'spacingNone', desktop: 'spacingLarge3X' }}>
        <Stack spacing="spacingLarge7X">
          <SectionList>
            {moduleData.topics.map((topic) => (
              <SectionListItem
                key={topic.id}
                id={`section${topic.id}`}
                color={subjectData.color}
                labels={topic.labels}
                name={topic.name}
              >
                {renderTopic(topic)}
              </SectionListItem>
            ))}
          </SectionList>
          <Box display={{ base: 'none', tablet: 'initial' }}>{React.cloneElement(backLink, { isCentered: true })}</Box>
        </Stack>
      </Container>

      {taskDialog}
      {!userData.isStudent && (
        <ActionBar
          selectedItemCount={selectedLessons.length}
          onClearSelection={() => Selecting.clear()}
          meta={<Duration duration={_.sumBy(selectedLessons, 'duration')} format="minutes" />}
        >
          <Button
            onClick={() =>
              openTaskDialog({
                contents: selectedLessons.map((lesson) => ({
                  duration: lesson.duration,
                  id: lesson.id,
                  name: lesson.name,
                  subjectCode: subjectData.code,
                  type: lesson.type,
                  url: lesson.url,
                  ...(lesson.challengeTier && { tier: lesson.challengeTier }),
                })),
              })
            }
          >
            Assign as task
          </Button>
        </ActionBar>
      )}
      {teacherRevisionDialog}
    </>
  );
}

function ModuleContainer(props: ModuleContainerProps) {
  const { router, params } = props;

  const { subscriptionId, classId, moduleId } = params;

  const {
    data: queryData,
    error,
    loading,
  } = useGetModule({
    accountId: subscriptionId,
    classId,
    moduleId,
  });

  if (loading && !queryData) {
    return <ModuleLoader />;
  }

  if (error) {
    return (
      <GraphQLError
        error={error}
        description="We couldn’t load the module for this class."
        customMessages={{
          NOT_FOUND: {
            description: 'We can’t seem to find the page you’re looking for.',
            heading: 'Oops, sorry!',
          },
        }}
      />
    );
  }

  invariant(queryData, 'Module data should be defined');

  return <Module queryData={queryData} params={params} router={router} />;
}

export default ModuleContainer;
